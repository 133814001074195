﻿const imageswiper = new Swiper('.imageswiper', {
    'slidesPerView': 1,
    'breakpoints': {
        '768': {
            'slidesPerView': 1
        },
        '1024': {
            'slidesPerView': 2
        }
    },
    'spaceBetween': 32,
    'loop': false,
    'navigation': {
        'nextEl': '.images-slider-next-button',
        'prevEl': '.images-slider-prev-button'
    }
});

const cardswiper = new Swiper('.cardswiper', {
    'slidesPerView': 1,
    'breakpoints': {
        '768': {
            'slidesPerView': 2
        },
        '1024': {
            'slidesPerView': 3
        }
    },
    'spaceBetween': 24,
    'loop': false,
    'navigation': {
        'nextEl': '.card-slider-next-button',
        'prevEl': '.card-slider-prev-button'
    }
});
const iconswiper = new Swiper('.iconswiper', {
    'slidesPerView': 2,
    'breakpoints': {
        '768': {
            'slidesPerView': 3
        },
        '980': {
            'slidesPerView': 4
        },
        '1200': {
            'slidesPerView': 5
        }
    },
    'spaceBetween': 24,
    'loop': false,
    'navigation': {
        'nextEl': '.icon-slider-next-button',
        'prevEl': '.icon-slider-prev-button'
    }
});
const logoswiper = new Swiper('.logoswiper', {
    'slidesPerView': 2,
    'breakpoints': {
        '480': {
            'slidesPerView': 3
        },
        '768': {
            'slidesPerView': 4
        },
        '980': {
            'slidesPerView': 5
        },
        '1200': {
            'slidesPerView': 6
        }
    },
    'spaceBetween': 32,
    'loop': true,
    'speed': 1200,
    'autoplay': {
        'delay': 3200,
        'disableOnInteraction': true,
        'pauseOnMouseEnter': true
    },
    'navigation': {
        'nextEl': '.logo-slider-next-button',
        'prevEl': '.logo-slider-prev-button'
    }
});